import React from "react";


const Settings = () => {

    return (
        <>
            I AM A SETTINGS
        </>
    )
}

export default Settings